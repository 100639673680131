<template>
    <div v-if="store.SystemState._deviceType !== DEVICE_TYPE_MOBILE" alt="pc">
        <div class="login-background">
            <div class="login-center">
                
                <div class="login-body">
                    <div class="logo-box">
                        <div>
                            <img src="../assets/images/pc/login-logo.png" alt="" style="width:227px; margin:0  auto 0 0; display:block;">
                            <!-- <p class="text" style="color:#2f3b89; font-size:14px;font-weight:bold; margin-top:10px;text-align:center; margin-left:10px; white-space:nowrap ;word-break:break-all">빠르고 안정적인 거래를 지원합니다.</p> -->
                        </div>
                    </div>
                    <div class="id-big-box">
                        <div class="id-box">
                            <div class="typing">
                                <p style="color:#fff; font-size:12px; margin-bottom:6px; ">아이디</p>
                                <div class="typing-box id">
                                    <!-- <img src="@/assets/images/id.svg" alt="아이디를 입력해주세요" /> -->
                                    <input type="text" placeholder="G&B 고객 아이디" v-model="state.id" />
                                </div>
                                <p style="color:#fff; font-size:12px; margin-bottom:6px; ">비밀번호</p>
                                <div class="typing-box">
                                    <!-- <img src="@/assets/images/pass.svg" alt="비밀번호를 입력해주세요" /> -->
                                    <input type="password" placeholder="접속 비밀번호 입력" v-model="state.pw" @keyup.enter="login()" />
                                </div>
                                <div class="flex check">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio1" v-model="state.isChecked1" @change="onCheckboxUpdated()" />
                                        <label class="form-check-label" for="inlineRadio1">아이디 저장</label>
                                    </div>
                                    <div class="form-check form-check-inline" v-if="store.SystemState._appMode !== 2">
                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio2" v-model="state.isChecked2" @change="onCheckboxUpdated()" />
                                        <label class="form-check-label" for="inlineRadio2">모의투자 접속</label>
                                    </div>
                                </div>
                                <button @click="login()" style="height:44px; font-size:18px;font-weight:bold;color:#fff; background:#5d4738; box-shadow:none; margin-bottom:0;">로그인</button>
                                <!-- <button @click="logout()">로그아웃</button> -->
                                <!-- <div class="flex warn">
                                    <img src="@/assets/images/warn.svg" alt="접속 비밀번호를 입력하십시오" />
                                    <p>접속 비밀번호를 입력하십시오</p>
                                </div> -->
                            </div>
                        </div>
                        <p style="color:#707070; font-size:12px; font-weight:500;margin-top:16px;text-align:center;">GNB인베스트먼트는 빠르고 안정적인 거래를 지원합니다.</p>
                    </div>
                </div>
                <div class="login-head">
                    <div class="item-box">
                        <p class="title">DOW JOENS</p>
                        <p class="price">34,711</p>
                        <p class="number">
                            <i class="icon-up"/>
                            <span class="text red">59.00 </span>
                            <span class="text red">+0.18%</span>
                        </p>
                    </div>
                    <div class="item-box">
                        <p class="title">NASDAQ</p>
                        <p class="price">13,693.75</p>
                        <p class="number">
                            <i class="icon-up"/>
                            <span class="text red">1.00 </span>
                            <span class="text red">+0.01%</span>
                        </p>
                    </div>
                    <div class="item-box">
                        <p class="title">S&P 500</p>
                        <p class="price">4,217.75</p>
                        <p class="number">
                            <i class="icon-up"/>
                            <span class="text red">5.25 </span>
                            <span class="text red">+0.02%</span>
                        </p>
                    </div>
                    <div class="item-box">
                        <p class="title">KOSPI</p>
                        <p class="price">427,36</p>
                        <p class="number">
                            <i class="icon-down"/>
                            <span class="text blue">1.41 </span>
                            <span class="text blue">-0.19%</span>
                        </p>
                    </div>
                    <div class="item-box">
                        <p class="title">EURO FX</p>
                        <p class="price">1,22910</p>
                        <p class="number">
                            <i class="icon-down"/>
                            <span class="text blue">0.76</span>
                            <span class="text blue">-0.02%</span>
                        </p>
                    </div>
                </div>
            </div>
            <PopupConfirm :isShowPopup="state.isShowPopup" :text="state.popupText" @close="close('PopupConfirm')" />
        </div>
    </div>
    <div v-else alt="mobile">
        <div class="login wrap">
            <!-- 첫 방문 -->
            <div class="first" v-if="state.first">
                <img src="@/assets/images/logo.png" alt="로고" class="logo" />
                <!-- <p class="eng">Your Global Property Partner</p> -->
            </div>
            <!-- 로그인 입력 -->
            <div class="typing" v-else>
                <img src="@/assets/images/logo.png" alt="로고" class="logo" />
                <!-- <p class="eng">Your Global Property Partner</p> -->
                <div class="typing-box id">
                    <!-- <img src="@/assets/images/id.svg" alt="아이디를 입력해주세요" /> -->
                    <input type="text" placeholder="G&B 고객 아이디" v-model="state.id" />
                </div>
                <div class="typing-box">
                    <!-- <img src="@/assets/images/pass.svg" alt="비밀번호를 입력해주세요" /> -->
                    <input type="password" placeholder="접속 비밀번호 입력" v-model="state.pw" @keyup.enter="login()" />
                </div>
                <div class="flex check">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio1" v-model="state.isChecked1" @change="onCheckboxUpdated()" />
                        <label class="form-check-label" for="inlineRadio1">아이디 저장</label>
                    </div>
                  <div class="form-check form-check-inline" v-if="store.SystemState._appMode !== 2">
                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio2" v-model="state.isChecked2" @change="onCheckboxUpdated()" />
                        <label class="form-check-label" for="inlineRadio2">모의투자 접속</label>
                    </div>
                </div>
                <button @click="login()">로그인</button>
                <!-- <button @click="logout()">로그아웃</button> -->
                <div class="flex warn">
                    <img src="@/assets/images/warn.svg" alt="접속 비밀번호를 입력하십시오" />
                    <p>접속 비밀번호를 입력하십시오</p>
                </div>
            </div>
            <PopupConfirm :isShowPopup="state.isShowPopup" :text="state.popupText" @close="close('PopupConfirm')" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { store, onMounted, onUnmounted, reactive } = useBaseComponent();
import { NetworkService, Emit_LoginFailed, Emit_ResetMoveToLogin } from "@/network"
// import { SettingsModel, SettingsExModel } from '../db';
import SettingsService from '../services/SettingsService';
import * as Constant from '../constants/Global';
// import * as AccPwd from '../constants/AccPwd';
import { DEVICE_TYPE_MOBILE } from "./../constants/Global";

let state = reactive({
    first: false,

    isShowPopup: false,
    popupText: "",

    id: '',
    pw: '',

    isChecked1: false,
    isChecked2: false,
    isFirstLogin: true, // 재접속 처리용
    loginState: 0,

    timerCheckboxUpdated: null as any,
});

onMounted( async () => {
    // const isMobileTestOnly = window.location.protocol.toLowerCase().startsWith('https:');
    // if(isMobileTestOnly) {
    //     await isPc();
    //     await isTablet();
    // }
    store.CommonState.isLoginPage = true;
    NetworkService.emitter.on(Emit_LoginFailed, event_LoginFailed );
    NetworkService.emitter.on(Emit_ResetMoveToLogin, event_ResetMoveToLogin );

    store.setLastViewPage('');

    if(store.LoginPageState.errorState && store.LoginPageState.errorState === 1 && store.LoginPageState.errorMessage) {
        // 로컬스토리지 캐싱
        resetAndReloadPage();
    }
    else if(store.LoginPageState.isReloadRequired) {
        // 메모리 캐싱
        resetAndReloadPage();
    }
    else {
        await loadPriorConfig();
    }
})
onUnmounted(()=> {
    store.CommonState.isLoginPage = false;
    NetworkService.emitter.off(Emit_LoginFailed, event_LoginFailed);
    NetworkService.emitter.off(Emit_ResetMoveToLogin, event_ResetMoveToLogin );
    if(state.timerCheckboxUpdated){
        clearTimeout(state.timerCheckboxUpdated);
    }
})

const onCheckboxUpdated = () => {
    state.timerCheckboxUpdated = setTimeout(function() {
        if(state.isChecked1 === false) {
            state.id = '';
            state.pw = '';
            store.updateLoginPassword('');
        }
        saveCurrentLoginInfo();
    }, 100);
}

const saveCurrentLoginInfo = async () => { store.saveLoginPageInfo(state.id, state.isChecked1, state.isChecked2); }
const loadPriorConfig = async () => {

    state.id = store.LoginPageState.id;
    state.isChecked1 = store.LoginPageState.isChecked1;
    state.isChecked2 = store.LoginPageState.isChecked2;
    state.loginState = store.LoginPageState.loginState;

    state.pw = await SettingsService.getUserPwd();
}

const login = () => {

    // 임시 모바일만 지원
    // const isMobileTestOnly = window.location.protocol.toLowerCase() === 'https:';
    // if(isMobileTestOnly) {
    //     if(isPc() || isTablet())
    //         return;
    // }

    if(NetworkService._socketState != Constant.SOCKET_STATE_OPEN) {
        store.setVisibleDialog({ message: '네트워크 연결이 원활하지 않습니다' });
        return;
    }

    // 아이디 소문자
    if(state.id)
        state.id = state.id.toLowerCase();

    const loginInfo = {
        userID: state.id,
        userPwd: state.pw,
        bFirstLogin: state.isFirstLogin ? 1 : 0,
        bMock: state.isChecked2 ? 1 : 0,
        eventFrom: 'loginPage',
    }

    store.SettingsState.loginID = state.id;

    // 계좌 팝업
    store.setSettingAccountPopupState(1);

    SettingsService.getUserId().then(priorId => {
        if(priorId && priorId == state.id) {
            store.initializeSettings(false);
            store.initializeSettingsEx(false);
        }
        else {
            // 다른 아이디로 로그인, 정보 초기화
            store.initializeSettings(true);
            store.initializeSettingsEx(true);
            store.initAccPwd();
        }

        store.LoginPageState.loginFrom = "loginPage";

        // 기존 저장로직용
        store.updateLoginId(state.id);
        store.updateLoginPassword(state.pw);

        // UI 선택용
        saveCurrentLoginInfo();

        setTimeout(() => {
            NetworkService.send.login(loginInfo);
        }, 100);
    })
}


const close = (popupType: string) => {
    switch (popupType) {
        case "PopupConfirm":
            state.isShowPopup = false;
            break;
    }
};

const event_LoginFailed = (params: any) => { 
    resetAndReloadPage(); 
};

const event_ResetMoveToLogin = (params: any) => { 
    resetAndReloadPage(); 
}

const resetAndReloadPage = () => {
    // 이전에 공용팝업에서 처리 된 이후 콜백임
    // 오류 초기화, 새로고침
    store.updateLoginPageState(0);
    store.updateLoginPageError(0, '');
    location.reload();
}

// const isPc = () => {
//     const ua = window.navigator.userAgent.toString().toLowerCase() || '';
//     if (ua.match(/linux|windows (nt|98)|macintosh|cros/) && !ua.match(/android|mobile|polaris|lgtelecom|uzard|natebrowser|ktf;|skt;/)) {
//         const message = 'PC 버전은 현재 지원하지 않습니다'
//         store.setVisibleDialog({ message: message,});
//         return true;
//     } 
//     return false;
// }

// const isTablet =()=> {
//     const ua = window.navigator.userAgent.toString().toLowerCase() || '';
//     if (ua.match(/ipad/) || (ua.match(/android/) && !ua.match(/mobi|mini|fennec/))) {
//         const message = 'PC 버전은 현재 지원하지 않습니다'
//         store.setVisibleDialog({ message: message,});
//         return true;
//     }
//     return false;
// }

// const isMobile = () => {
//     const ua = window.navigator.userAgent.toString().toLowerCase() || '';
//     if (ua.match(/ip(hone|od)|android.+mobile|windows (ce|phone)|blackberry|bb10|symbian|webos|firefox.+fennec|opera m(ob|in)i|polaris|iemobile|lgtelecom|nokia|sonyericsson|dolfin|uzard|natebrowser|ktf;|skt;/)) {
//         return true;
//     } else {
//         return false;
//     }
// }

</script>
<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
    .devicePc .login-head .item-box .price{font-family: 'Poppins', sans-serif; line-height: 1.1;}
</style>